/* eslint-disable */
import * as types from "./graphql";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  query SignupPage {\n    me {\n      id\n    }\n  }\n":
    types.SignupPageDocument,
  "\n  query InternalLayoutQuery {\n    ...SidebarQuery\n  }\n":
    types.InternalLayoutQueryDocument,
  "\n  query ChatPage($id: ID!) {\n    chat(id: $id) {\n      ...MessageFormChat\n\n      id\n      name\n\n      currentVersion {\n        ...MessageListChatVersion\n      }\n    }\n  }\n":
    types.ChatPageDocument,
  "\n  query LogoutPageQuery {\n    me {\n      id\n    }\n  }\n":
    types.LogoutPageQueryDocument,
  "\n  fragment AccountPageUser on User {\n    id\n    fullName\n    displayName\n    clinicName\n    email\n  }\n":
    types.AccountPageUserFragmentDoc,
  "\n  query AccountQuery {\n    me {\n      email\n      ...AccountPageUser\n    }\n  }\n":
    types.AccountQueryDocument,
  "\n  mutation UpdateAccountMutation($profile: ProfileInput!) {\n    profileUpdate(profile: $profile) {\n      errors {\n        field\n        message\n      }\n\n      profile {\n        ...AccountPageUser\n      }\n    }\n  }\n":
    types.UpdateAccountMutationDocument,
  "\n  query AuthTrackerUser {\n    me {\n      id\n      email\n      fullName\n      displayName\n      email\n      clinicName\n      createdAt\n    }\n  }\n":
    types.AuthTrackerUserDocument,
  "\n  mutation Register(\n    $email: String!\n    $password: String!\n    $fullName: String!\n    $recaptchaToken: String!\n    $displayName: String\n    $clinicName: String\n  ) {\n    register(\n      profile: {\n        credentials: { email: $email, password: $password }\n        profile: {\n          fullName: $fullName\n          displayName: $displayName\n          clinicName: $clinicName\n        }\n        challenge: { recaptcha_token: $recaptchaToken }\n      }\n    ) {\n      errors {\n        message\n        field\n      }\n    }\n  }\n":
    types.RegisterDocument,
  "\n  fragment ChatItemChatVersion on ChatVersion {\n    id\n    updatedAt\n\n    lastMessage {\n      id\n      text\n    }\n  }\n":
    types.ChatItemChatVersionFragmentDoc,
  "\n  fragment ChatItemChat on Chat {\n    id\n    name\n\n    currentVersion {\n      ...ChatItemChatVersion\n    }\n  }\n":
    types.ChatItemChatFragmentDoc,
  "\n  fragment ChatListChat on Chat {\n    id\n    ...ChatItemChat\n  }\n":
    types.ChatListChatFragmentDoc,
  "\n  query ChatList {\n    chats {\n      ...ChatListChat\n    }\n  }\n":
    types.ChatListDocument,
  "\n  mutation DeleteChat($chatId: ID!) {\n    chatDelete(chatId: $chatId) {\n      errors {\n        message\n        field\n      }\n    }\n  }\n":
    types.DeleteChatDocument,
  "\n  mutation ChatNameForm($chatId: ID!, $chat: ChatInput!) {\n    chatUpdate(chatId: $chatId, chat: $chat) {\n      errors {\n        message\n        field\n      }\n\n      chat {\n        id\n        name\n        updatedAt\n      }\n    }\n  }\n":
    types.ChatNameFormDocument,
  "\n  fragment AssistantGroupMessage on Message {\n    sources {\n      type\n      citation {\n        text\n        url\n      }\n    }\n    ...MessageItemMessage\n  }\n":
    types.AssistantGroupMessageFragmentDoc,
  "\n  fragment HumanGroupMessage on Message {\n    ...MessageItemMessage\n  }\n":
    types.HumanGroupMessageFragmentDoc,
  "\n  fragment CreateChatChat on Chat {\n    ...SendMessageChat\n    id\n  }\n":
    types.CreateChatChatFragmentDoc,
  "\n  mutation CreateChat($chat: ChatInput!, $message: MessageInput!) {\n    chatCreate: chatCreateV3(chat: $chat, message: $message) {\n      userErrors {\n        message\n        field\n      }\n\n      chat {\n        ...CreateChatChat\n        id\n      }\n    }\n  }\n":
    types.CreateChatDocument,
  "\n  fragment SendMessageMessage on Message {\n    id\n    chat {\n      id\n    }\n  }\n":
    types.SendMessageMessageFragmentDoc,
  "\n  fragment SendMessageChatVersion on ChatVersion {\n    ...MessageListChatVersion\n\n    id\n    messages {\n      id\n      ...SendMessageMessage\n    }\n  }\n":
    types.SendMessageChatVersionFragmentDoc,
  "\n  fragment SendMessageChat on Chat {\n    id\n    ...ChatItemChat\n\n    versions {\n      ...SendMessageChatVersion\n    }\n\n    currentVersion {\n      ...SendMessageChatVersion\n    }\n  }\n":
    types.SendMessageChatFragmentDoc,
  "\n  mutation SendMessages($replyToMessageId: ID!, $message: MessageInput!) {\n    messageSend: messageSendV3(\n      replyToMessageId: $replyToMessageId\n      message: $message\n    ) {\n      userErrors {\n        message\n        field\n      }\n\n      chat {\n        ...SendMessageChat\n      }\n    }\n  }\n":
    types.SendMessagesDocument,
  "\n  fragment MessageDayMessage on Message {\n    ...AssistantGroupMessage\n    ...HumanGroupMessage\n  }\n":
    types.MessageDayMessageFragmentDoc,
  "\n  fragment MessageFormChat on Chat {\n    ...SendMessageChat\n    id\n  }\n":
    types.MessageFormChatFragmentDoc,
  "\n  fragment MessageItemMessage on Message {\n    text\n    updatedAt\n    citations {\n      text\n      url\n    }\n\n    participant {\n      __typename\n      name\n\n      ... on UserParticipant {\n        user {\n          isMe\n        }\n      }\n    }\n  }\n":
    types.MessageItemMessageFragmentDoc,
  "\n  fragment MessageListChatVersion on ChatVersion {\n    id\n\n    messages {\n      id\n      updatedAt\n\n      ...MessageDayMessage\n    }\n  }\n":
    types.MessageListChatVersionFragmentDoc,
  "\n  fragment ArticleMessageMessage on Message {\n    text\n    sources {\n      type\n      citation {\n        text\n        url\n      }\n    }\n  }\n":
    types.ArticleMessageMessageFragmentDoc,
  "\n  fragment ProfileMenuQuery on Query {\n    me {\n      id\n      fullName\n      displayName\n    }\n  }\n":
    types.ProfileMenuQueryFragmentDoc,
  "\n  fragment SidebarQuery on Query {\n    ...ProfileMenuQuery\n  }\n":
    types.SidebarQueryFragmentDoc,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query SignupPage {\n    me {\n      id\n    }\n  }\n"
): (typeof documents)["\n  query SignupPage {\n    me {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query InternalLayoutQuery {\n    ...SidebarQuery\n  }\n"
): (typeof documents)["\n  query InternalLayoutQuery {\n    ...SidebarQuery\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query ChatPage($id: ID!) {\n    chat(id: $id) {\n      ...MessageFormChat\n\n      id\n      name\n\n      currentVersion {\n        ...MessageListChatVersion\n      }\n    }\n  }\n"
): (typeof documents)["\n  query ChatPage($id: ID!) {\n    chat(id: $id) {\n      ...MessageFormChat\n\n      id\n      name\n\n      currentVersion {\n        ...MessageListChatVersion\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query LogoutPageQuery {\n    me {\n      id\n    }\n  }\n"
): (typeof documents)["\n  query LogoutPageQuery {\n    me {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment AccountPageUser on User {\n    id\n    fullName\n    displayName\n    clinicName\n    email\n  }\n"
): (typeof documents)["\n  fragment AccountPageUser on User {\n    id\n    fullName\n    displayName\n    clinicName\n    email\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query AccountQuery {\n    me {\n      email\n      ...AccountPageUser\n    }\n  }\n"
): (typeof documents)["\n  query AccountQuery {\n    me {\n      email\n      ...AccountPageUser\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation UpdateAccountMutation($profile: ProfileInput!) {\n    profileUpdate(profile: $profile) {\n      errors {\n        field\n        message\n      }\n\n      profile {\n        ...AccountPageUser\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation UpdateAccountMutation($profile: ProfileInput!) {\n    profileUpdate(profile: $profile) {\n      errors {\n        field\n        message\n      }\n\n      profile {\n        ...AccountPageUser\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query AuthTrackerUser {\n    me {\n      id\n      email\n      fullName\n      displayName\n      email\n      clinicName\n      createdAt\n    }\n  }\n"
): (typeof documents)["\n  query AuthTrackerUser {\n    me {\n      id\n      email\n      fullName\n      displayName\n      email\n      clinicName\n      createdAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation Register(\n    $email: String!\n    $password: String!\n    $fullName: String!\n    $recaptchaToken: String!\n    $displayName: String\n    $clinicName: String\n  ) {\n    register(\n      profile: {\n        credentials: { email: $email, password: $password }\n        profile: {\n          fullName: $fullName\n          displayName: $displayName\n          clinicName: $clinicName\n        }\n        challenge: { recaptcha_token: $recaptchaToken }\n      }\n    ) {\n      errors {\n        message\n        field\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation Register(\n    $email: String!\n    $password: String!\n    $fullName: String!\n    $recaptchaToken: String!\n    $displayName: String\n    $clinicName: String\n  ) {\n    register(\n      profile: {\n        credentials: { email: $email, password: $password }\n        profile: {\n          fullName: $fullName\n          displayName: $displayName\n          clinicName: $clinicName\n        }\n        challenge: { recaptcha_token: $recaptchaToken }\n      }\n    ) {\n      errors {\n        message\n        field\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ChatItemChatVersion on ChatVersion {\n    id\n    updatedAt\n\n    lastMessage {\n      id\n      text\n    }\n  }\n"
): (typeof documents)["\n  fragment ChatItemChatVersion on ChatVersion {\n    id\n    updatedAt\n\n    lastMessage {\n      id\n      text\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ChatItemChat on Chat {\n    id\n    name\n\n    currentVersion {\n      ...ChatItemChatVersion\n    }\n  }\n"
): (typeof documents)["\n  fragment ChatItemChat on Chat {\n    id\n    name\n\n    currentVersion {\n      ...ChatItemChatVersion\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ChatListChat on Chat {\n    id\n    ...ChatItemChat\n  }\n"
): (typeof documents)["\n  fragment ChatListChat on Chat {\n    id\n    ...ChatItemChat\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query ChatList {\n    chats {\n      ...ChatListChat\n    }\n  }\n"
): (typeof documents)["\n  query ChatList {\n    chats {\n      ...ChatListChat\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation DeleteChat($chatId: ID!) {\n    chatDelete(chatId: $chatId) {\n      errors {\n        message\n        field\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation DeleteChat($chatId: ID!) {\n    chatDelete(chatId: $chatId) {\n      errors {\n        message\n        field\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation ChatNameForm($chatId: ID!, $chat: ChatInput!) {\n    chatUpdate(chatId: $chatId, chat: $chat) {\n      errors {\n        message\n        field\n      }\n\n      chat {\n        id\n        name\n        updatedAt\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation ChatNameForm($chatId: ID!, $chat: ChatInput!) {\n    chatUpdate(chatId: $chatId, chat: $chat) {\n      errors {\n        message\n        field\n      }\n\n      chat {\n        id\n        name\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment AssistantGroupMessage on Message {\n    sources {\n      type\n      citation {\n        text\n        url\n      }\n    }\n    ...MessageItemMessage\n  }\n"
): (typeof documents)["\n  fragment AssistantGroupMessage on Message {\n    sources {\n      type\n      citation {\n        text\n        url\n      }\n    }\n    ...MessageItemMessage\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment HumanGroupMessage on Message {\n    ...MessageItemMessage\n  }\n"
): (typeof documents)["\n  fragment HumanGroupMessage on Message {\n    ...MessageItemMessage\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment CreateChatChat on Chat {\n    ...SendMessageChat\n    id\n  }\n"
): (typeof documents)["\n  fragment CreateChatChat on Chat {\n    ...SendMessageChat\n    id\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation CreateChat($chat: ChatInput!, $message: MessageInput!) {\n    chatCreate: chatCreateV3(chat: $chat, message: $message) {\n      userErrors {\n        message\n        field\n      }\n\n      chat {\n        ...CreateChatChat\n        id\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation CreateChat($chat: ChatInput!, $message: MessageInput!) {\n    chatCreate: chatCreateV3(chat: $chat, message: $message) {\n      userErrors {\n        message\n        field\n      }\n\n      chat {\n        ...CreateChatChat\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment SendMessageMessage on Message {\n    id\n    chat {\n      id\n    }\n  }\n"
): (typeof documents)["\n  fragment SendMessageMessage on Message {\n    id\n    chat {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment SendMessageChatVersion on ChatVersion {\n    ...MessageListChatVersion\n\n    id\n    messages {\n      id\n      ...SendMessageMessage\n    }\n  }\n"
): (typeof documents)["\n  fragment SendMessageChatVersion on ChatVersion {\n    ...MessageListChatVersion\n\n    id\n    messages {\n      id\n      ...SendMessageMessage\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment SendMessageChat on Chat {\n    id\n    ...ChatItemChat\n\n    versions {\n      ...SendMessageChatVersion\n    }\n\n    currentVersion {\n      ...SendMessageChatVersion\n    }\n  }\n"
): (typeof documents)["\n  fragment SendMessageChat on Chat {\n    id\n    ...ChatItemChat\n\n    versions {\n      ...SendMessageChatVersion\n    }\n\n    currentVersion {\n      ...SendMessageChatVersion\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation SendMessages($replyToMessageId: ID!, $message: MessageInput!) {\n    messageSend: messageSendV3(\n      replyToMessageId: $replyToMessageId\n      message: $message\n    ) {\n      userErrors {\n        message\n        field\n      }\n\n      chat {\n        ...SendMessageChat\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation SendMessages($replyToMessageId: ID!, $message: MessageInput!) {\n    messageSend: messageSendV3(\n      replyToMessageId: $replyToMessageId\n      message: $message\n    ) {\n      userErrors {\n        message\n        field\n      }\n\n      chat {\n        ...SendMessageChat\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment MessageDayMessage on Message {\n    ...AssistantGroupMessage\n    ...HumanGroupMessage\n  }\n"
): (typeof documents)["\n  fragment MessageDayMessage on Message {\n    ...AssistantGroupMessage\n    ...HumanGroupMessage\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment MessageFormChat on Chat {\n    ...SendMessageChat\n    id\n  }\n"
): (typeof documents)["\n  fragment MessageFormChat on Chat {\n    ...SendMessageChat\n    id\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment MessageItemMessage on Message {\n    text\n    updatedAt\n    citations {\n      text\n      url\n    }\n\n    participant {\n      __typename\n      name\n\n      ... on UserParticipant {\n        user {\n          isMe\n        }\n      }\n    }\n  }\n"
): (typeof documents)["\n  fragment MessageItemMessage on Message {\n    text\n    updatedAt\n    citations {\n      text\n      url\n    }\n\n    participant {\n      __typename\n      name\n\n      ... on UserParticipant {\n        user {\n          isMe\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment MessageListChatVersion on ChatVersion {\n    id\n\n    messages {\n      id\n      updatedAt\n\n      ...MessageDayMessage\n    }\n  }\n"
): (typeof documents)["\n  fragment MessageListChatVersion on ChatVersion {\n    id\n\n    messages {\n      id\n      updatedAt\n\n      ...MessageDayMessage\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ArticleMessageMessage on Message {\n    text\n    sources {\n      type\n      citation {\n        text\n        url\n      }\n    }\n  }\n"
): (typeof documents)["\n  fragment ArticleMessageMessage on Message {\n    text\n    sources {\n      type\n      citation {\n        text\n        url\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ProfileMenuQuery on Query {\n    me {\n      id\n      fullName\n      displayName\n    }\n  }\n"
): (typeof documents)["\n  fragment ProfileMenuQuery on Query {\n    me {\n      id\n      fullName\n      displayName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment SidebarQuery on Query {\n    ...ProfileMenuQuery\n  }\n"
): (typeof documents)["\n  fragment SidebarQuery on Query {\n    ...ProfileMenuQuery\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
