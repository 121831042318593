/* eslint-disable */
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types */

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  TimeElapsed: { input: any; output: any };
};

export type AdditionalEntityFields = {
  path?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssistantParticipant = Participant & {
  __typename?: "AssistantParticipant";
  avatar?: Maybe<Scalars["String"]["output"]>;
  chat: Chat;
  /** @deprecated Use nameV2 */
  name: Scalars["String"]["output"];
  nameV2: UserName;
};

export type ChallengeInput = {
  recaptcha_token: Scalars["String"]["input"];
};

export type Chat = {
  __typename?: "Chat";
  /** @deprecated Use createdAtV2 */
  createdAt: Scalars["DateTime"]["output"];
  createdAtV2: FormattedTime;
  currentVersion: ChatVersion;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  participants: Array<User>;
  preview?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use updatedAtV2 */
  updatedAt: Scalars["DateTime"]["output"];
  updatedAtV2: FormattedTime;
  versions: Array<ChatVersion>;
};

export type ChatConnection = {
  __typename?: "ChatConnection";
  edges: Array<ChatEdge>;
  id: Scalars["String"]["output"];
  pageInfo: PageInfo;
};

export type ChatCreatePayloadV3 = PayloadV2 & {
  __typename?: "ChatCreatePayloadV3";
  chat?: Maybe<Chat>;
  userErrors: Array<UserError>;
};

export type ChatDeletePayload = Payload & {
  __typename?: "ChatDeletePayload";
  errors: Array<UserError>;
};

export type ChatEdge = {
  __typename?: "ChatEdge";
  cursor: Scalars["String"]["output"];
  node: Chat;
};

export type ChatInput = {
  name: Scalars["String"]["input"];
};

export type ChatSort = {
  direction: SortDirection;
  field: ChatSortField;
};

export enum ChatSortField {
  CreatedAt = "CREATED_AT",
  Name = "NAME",
  UpdatedAt = "UPDATED_AT",
}

export type ChatUpdatePayload = Payload & {
  __typename?: "ChatUpdatePayload";
  chat?: Maybe<Chat>;
  errors: Array<UserError>;
};

export type ChatVersion = {
  __typename?: "ChatVersion";
  chat: Chat;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  lastMessage?: Maybe<Message>;
  messages: Array<Message>;
  updatedAt: Scalars["DateTime"]["output"];
  version: Scalars["Int"]["output"];
};

export type Citation = {
  __typename?: "Citation";
  text?: Maybe<Scalars["String"]["output"]>;
  url: Scalars["String"]["output"];
};

export type CredentialsInput = {
  email: Scalars["String"]["input"];
  password?: InputMaybe<Scalars["String"]["input"]>;
};

export enum EngineActor {
  Ai = "AI",
  Human = "HUMAN",
  System = "SYSTEM",
}

export type EngineHistory = {
  __typename?: "EngineHistory";
  createdAt: Scalars["DateTime"]["output"];
  engineMessages: Array<EngineMessage>;
  id: Scalars["ID"]["output"];
  messages: Array<Message>;
  usage?: Maybe<EngineUsage>;
};

export type EngineMessage = {
  __typename?: "EngineMessage";
  actor: EngineActor;
  text: Scalars["String"]["output"];
};

export type EngineUsage = {
  __typename?: "EngineUsage";
  openAi: OpenAiUsage;
};

export type FormattedTime = {
  __typename?: "FormattedTime";
  elapsed: Scalars["TimeElapsed"]["output"];
  global: Scalars["String"]["output"];
  local: Scalars["String"]["output"];
};

export type FormattedTimeLocalArgs = {
  language: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type Message = {
  __typename?: "Message";
  chat: Chat;
  /** @deprecated Use sources */
  citations?: Maybe<Array<Citation>>;
  createdAt: Scalars["DateTime"]["output"];
  engineHistory?: Maybe<Array<EngineMessage>>;
  id: Scalars["ID"]["output"];
  participant: Participant;
  sources?: Maybe<Array<Source>>;
  text: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type MessageInput = {
  text: Scalars["String"]["input"];
};

export type MessageSendPayloadV3 = PayloadV2 & {
  __typename?: "MessageSendPayloadV3";
  chat?: Maybe<Chat>;
  userErrors: Array<UserError>;
};

export enum MessageSourceType {
  Article = "ARTICLE",
  Generated = "GENERATED",
}

export type Mutation = {
  __typename?: "Mutation";
  chatCreateV3: ChatCreatePayloadV3;
  chatDelete: ChatDeletePayload;
  chatUpdate: ChatUpdatePayload;
  messageSendV3: MessageSendPayloadV3;
  profileUpdate: ProfileUpdatePayload;
  register: RegisterPayload;
};

export type MutationChatCreateV3Args = {
  chat: ChatInput;
  message?: InputMaybe<MessageInput>;
};

export type MutationChatDeleteArgs = {
  chatId: Scalars["ID"]["input"];
};

export type MutationChatUpdateArgs = {
  chat: ChatInput;
  chatId: Scalars["ID"]["input"];
};

export type MutationMessageSendV3Args = {
  message: MessageInput;
  replyToMessageId: Scalars["ID"]["input"];
};

export type MutationProfileUpdateArgs = {
  profile: ProfileInput;
};

export type MutationRegisterArgs = {
  profile: ProfileInput;
};

export type OpenAiChat = {
  __typename?: "OpenAiChat";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  messages: Array<OpenAiMessage>;
  sourceMessage: Message;
  updatedAt: Scalars["DateTime"]["output"];
};

export type OpenAiFunctionCall = {
  __typename?: "OpenAiFunctionCall";
  arguments: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type OpenAiMessage = {
  __typename?: "OpenAiMessage";
  chat: OpenAiChat;
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  functionCall?: Maybe<OpenAiFunctionCall>;
  name?: Maybe<Scalars["String"]["output"]>;
  role: OpenAiMessageRole;
  updatedAt: Scalars["DateTime"]["output"];
  usage?: Maybe<OpenAiUsage>;
};

export enum OpenAiMessageRole {
  Assistant = "ASSISTANT",
  Function = "FUNCTION",
  System = "SYSTEM",
  User = "USER",
}

export type OpenAiUsage = {
  __typename?: "OpenAiUsage";
  completionTokens: Scalars["Int"]["output"];
  promptTokens: Scalars["Int"]["output"];
  totalTokens?: Maybe<Scalars["Int"]["output"]>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type Participant = {
  avatar?: Maybe<Scalars["String"]["output"]>;
  chat: Chat;
  /** @deprecated Use nameV2 */
  name: Scalars["String"]["output"];
  nameV2: UserName;
};

export enum ParticipantType {
  Assistant = "ASSISTANT",
  User = "USER",
}

export type Payload = {
  errors: Array<UserError>;
};

export type PayloadV2 = {
  userErrors: Array<UserError>;
};

export type ProfileInput = {
  challenge?: InputMaybe<ChallengeInput>;
  credentials: CredentialsInput;
  profile: UserInput;
};

export type ProfileUpdatePayload = Payload & {
  __typename?: "ProfileUpdatePayload";
  errors: Array<UserError>;
  profile?: Maybe<User>;
};

export type Query = {
  __typename?: "Query";
  chat?: Maybe<Chat>;
  chats: Array<Chat>;
  chatsV2: ChatConnection;
  me?: Maybe<User>;
  user?: Maybe<User>;
  users: UserConnection;
};

export type QueryChatArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryChatsV2Args = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ChatSort>>;
};

export type QueryUserArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserSort>>;
};

export type RegisterPayload = Payload & {
  __typename?: "RegisterPayload";
  errors: Array<UserError>;
  profile?: Maybe<User>;
};

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type Source = {
  __typename?: "Source";
  citation?: Maybe<Citation>;
  type: MessageSourceType;
};

export type User = {
  __typename?: "User";
  chats: ChatConnection;
  clinicName?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  /** @deprecated Use name.full */
  displayName?: Maybe<Scalars["String"]["output"]>;
  email: Scalars["String"]["output"];
  /** @deprecated Use name.display */
  fullName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isAdmin: Scalars["Boolean"]["output"];
  isMe: Scalars["Boolean"]["output"];
  name: UserName;
  updatedAt: Scalars["DateTime"]["output"];
};

export type UserChatsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ChatSort>>;
};

export type UserConnection = {
  __typename?: "UserConnection";
  edges: Array<UserEdge>;
  id: Scalars["String"]["output"];
  pageInfo: PageInfo;
};

export type UserEdge = {
  __typename?: "UserEdge";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type UserError = {
  __typename?: "UserError";
  field?: Maybe<Array<Scalars["String"]["output"]>>;
  message: Scalars["String"]["output"];
};

export type UserInput = {
  clinicName?: InputMaybe<Scalars["String"]["input"]>;
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  fullName: Scalars["String"]["input"];
};

export type UserName = {
  __typename?: "UserName";
  display: Scalars["String"]["output"];
  full: Scalars["String"]["output"];
  initials: Scalars["String"]["output"];
};

export type UserParticipant = Participant & {
  __typename?: "UserParticipant";
  avatar?: Maybe<Scalars["String"]["output"]>;
  chat: Chat;
  /** @deprecated Use nameV2 */
  name: Scalars["String"]["output"];
  nameV2: UserName;
  user: User;
};

export type UserSort = {
  direction: SortDirection;
  field: UserSortField;
};

export enum UserSortField {
  ClinicName = "CLINIC_NAME",
  CreatedAt = "CREATED_AT",
  DisplayName = "DISPLAY_NAME",
  Email = "EMAIL",
  FullName = "FULL_NAME",
  UpdatedAt = "UPDATED_AT",
}

export type SignupPageQueryVariables = Exact<{ [key: string]: never }>;

export type SignupPageQuery = {
  __typename?: "Query";
  me?: { __typename?: "User"; id: string } | null;
};

export type InternalLayoutQueryQueryVariables = Exact<{ [key: string]: never }>;

export type InternalLayoutQueryQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    id: string;
    fullName: string;
    displayName?: string | null;
  } | null;
};

export type ChatPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ChatPageQuery = {
  __typename?: "Query";
  chat?: {
    __typename?: "Chat";
    id: string;
    name: string;
    currentVersion: {
      __typename?: "ChatVersion";
      id: string;
      updatedAt: any;
      messages: Array<{
        __typename?: "Message";
        id: string;
        updatedAt: any;
        text: string;
        chat: { __typename?: "Chat"; id: string };
        sources?: Array<{
          __typename?: "Source";
          type: MessageSourceType;
          citation?: {
            __typename?: "Citation";
            text?: string | null;
            url: string;
          } | null;
        }> | null;
        citations?: Array<{
          __typename?: "Citation";
          text?: string | null;
          url: string;
        }> | null;
        participant:
          | { __typename: "AssistantParticipant"; name: string }
          | {
              __typename: "UserParticipant";
              name: string;
              user: { __typename?: "User"; isMe: boolean };
            };
      }>;
      lastMessage?: { __typename?: "Message"; id: string; text: string } | null;
    };
    versions: Array<{
      __typename?: "ChatVersion";
      id: string;
      messages: Array<{
        __typename?: "Message";
        id: string;
        updatedAt: any;
        text: string;
        chat: { __typename?: "Chat"; id: string };
        sources?: Array<{
          __typename?: "Source";
          type: MessageSourceType;
          citation?: {
            __typename?: "Citation";
            text?: string | null;
            url: string;
          } | null;
        }> | null;
        citations?: Array<{
          __typename?: "Citation";
          text?: string | null;
          url: string;
        }> | null;
        participant:
          | { __typename: "AssistantParticipant"; name: string }
          | {
              __typename: "UserParticipant";
              name: string;
              user: { __typename?: "User"; isMe: boolean };
            };
      }>;
    }>;
  } | null;
};

export type LogoutPageQueryQueryVariables = Exact<{ [key: string]: never }>;

export type LogoutPageQueryQuery = {
  __typename?: "Query";
  me?: { __typename?: "User"; id: string } | null;
};

export type AccountPageUserFragment = {
  __typename?: "User";
  id: string;
  fullName: string;
  displayName?: string | null;
  clinicName?: string | null;
  email: string;
};

export type AccountQueryQueryVariables = Exact<{ [key: string]: never }>;

export type AccountQueryQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    email: string;
    id: string;
    fullName: string;
    displayName?: string | null;
    clinicName?: string | null;
  } | null;
};

export type UpdateAccountMutationMutationVariables = Exact<{
  profile: ProfileInput;
}>;

export type UpdateAccountMutationMutation = {
  __typename?: "Mutation";
  profileUpdate: {
    __typename?: "ProfileUpdatePayload";
    errors: Array<{
      __typename?: "UserError";
      field?: Array<string> | null;
      message: string;
    }>;
    profile?: {
      __typename?: "User";
      id: string;
      fullName: string;
      displayName?: string | null;
      clinicName?: string | null;
      email: string;
    } | null;
  };
};

export type AuthTrackerUserQueryVariables = Exact<{ [key: string]: never }>;

export type AuthTrackerUserQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    id: string;
    email: string;
    fullName: string;
    displayName?: string | null;
    clinicName?: string | null;
    createdAt: any;
  } | null;
};

export type RegisterMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  fullName: Scalars["String"]["input"];
  recaptchaToken: Scalars["String"]["input"];
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  clinicName?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RegisterMutation = {
  __typename?: "Mutation";
  register: {
    __typename?: "RegisterPayload";
    errors: Array<{
      __typename?: "UserError";
      message: string;
      field?: Array<string> | null;
    }>;
  };
};

export type ChatItemChatVersionFragment = {
  __typename?: "ChatVersion";
  id: string;
  updatedAt: any;
  lastMessage?: { __typename?: "Message"; id: string; text: string } | null;
};

export type ChatItemChatFragment = {
  __typename?: "Chat";
  id: string;
  name: string;
  currentVersion: {
    __typename?: "ChatVersion";
    id: string;
    updatedAt: any;
    lastMessage?: { __typename?: "Message"; id: string; text: string } | null;
  };
};

export type ChatListChatFragment = {
  __typename?: "Chat";
  id: string;
  name: string;
  currentVersion: {
    __typename?: "ChatVersion";
    id: string;
    updatedAt: any;
    lastMessage?: { __typename?: "Message"; id: string; text: string } | null;
  };
};

export type ChatListQueryVariables = Exact<{ [key: string]: never }>;

export type ChatListQuery = {
  __typename?: "Query";
  chats: Array<{
    __typename?: "Chat";
    id: string;
    name: string;
    currentVersion: {
      __typename?: "ChatVersion";
      id: string;
      updatedAt: any;
      lastMessage?: { __typename?: "Message"; id: string; text: string } | null;
    };
  }>;
};

export type DeleteChatMutationVariables = Exact<{
  chatId: Scalars["ID"]["input"];
}>;

export type DeleteChatMutation = {
  __typename?: "Mutation";
  chatDelete: {
    __typename?: "ChatDeletePayload";
    errors: Array<{
      __typename?: "UserError";
      message: string;
      field?: Array<string> | null;
    }>;
  };
};

export type ChatNameFormMutationVariables = Exact<{
  chatId: Scalars["ID"]["input"];
  chat: ChatInput;
}>;

export type ChatNameFormMutation = {
  __typename?: "Mutation";
  chatUpdate: {
    __typename?: "ChatUpdatePayload";
    errors: Array<{
      __typename?: "UserError";
      message: string;
      field?: Array<string> | null;
    }>;
    chat?: {
      __typename?: "Chat";
      id: string;
      name: string;
      updatedAt: any;
    } | null;
  };
};

export type AssistantGroupMessageFragment = {
  __typename?: "Message";
  text: string;
  updatedAt: any;
  sources?: Array<{
    __typename?: "Source";
    type: MessageSourceType;
    citation?: {
      __typename?: "Citation";
      text?: string | null;
      url: string;
    } | null;
  }> | null;
  citations?: Array<{
    __typename?: "Citation";
    text?: string | null;
    url: string;
  }> | null;
  participant:
    | { __typename: "AssistantParticipant"; name: string }
    | {
        __typename: "UserParticipant";
        name: string;
        user: { __typename?: "User"; isMe: boolean };
      };
};

export type HumanGroupMessageFragment = {
  __typename?: "Message";
  text: string;
  updatedAt: any;
  citations?: Array<{
    __typename?: "Citation";
    text?: string | null;
    url: string;
  }> | null;
  participant:
    | { __typename: "AssistantParticipant"; name: string }
    | {
        __typename: "UserParticipant";
        name: string;
        user: { __typename?: "User"; isMe: boolean };
      };
};

export type CreateChatChatFragment = {
  __typename?: "Chat";
  id: string;
  name: string;
  versions: Array<{
    __typename?: "ChatVersion";
    id: string;
    messages: Array<{
      __typename?: "Message";
      id: string;
      updatedAt: any;
      text: string;
      chat: { __typename?: "Chat"; id: string };
      sources?: Array<{
        __typename?: "Source";
        type: MessageSourceType;
        citation?: {
          __typename?: "Citation";
          text?: string | null;
          url: string;
        } | null;
      }> | null;
      citations?: Array<{
        __typename?: "Citation";
        text?: string | null;
        url: string;
      }> | null;
      participant:
        | { __typename: "AssistantParticipant"; name: string }
        | {
            __typename: "UserParticipant";
            name: string;
            user: { __typename?: "User"; isMe: boolean };
          };
    }>;
  }>;
  currentVersion: {
    __typename?: "ChatVersion";
    id: string;
    updatedAt: any;
    messages: Array<{
      __typename?: "Message";
      id: string;
      updatedAt: any;
      text: string;
      chat: { __typename?: "Chat"; id: string };
      sources?: Array<{
        __typename?: "Source";
        type: MessageSourceType;
        citation?: {
          __typename?: "Citation";
          text?: string | null;
          url: string;
        } | null;
      }> | null;
      citations?: Array<{
        __typename?: "Citation";
        text?: string | null;
        url: string;
      }> | null;
      participant:
        | { __typename: "AssistantParticipant"; name: string }
        | {
            __typename: "UserParticipant";
            name: string;
            user: { __typename?: "User"; isMe: boolean };
          };
    }>;
    lastMessage?: { __typename?: "Message"; id: string; text: string } | null;
  };
};

export type CreateChatMutationVariables = Exact<{
  chat: ChatInput;
  message: MessageInput;
}>;

export type CreateChatMutation = {
  __typename?: "Mutation";
  chatCreate: {
    __typename?: "ChatCreatePayloadV3";
    userErrors: Array<{
      __typename?: "UserError";
      message: string;
      field?: Array<string> | null;
    }>;
    chat?: {
      __typename?: "Chat";
      id: string;
      name: string;
      versions: Array<{
        __typename?: "ChatVersion";
        id: string;
        messages: Array<{
          __typename?: "Message";
          id: string;
          updatedAt: any;
          text: string;
          chat: { __typename?: "Chat"; id: string };
          sources?: Array<{
            __typename?: "Source";
            type: MessageSourceType;
            citation?: {
              __typename?: "Citation";
              text?: string | null;
              url: string;
            } | null;
          }> | null;
          citations?: Array<{
            __typename?: "Citation";
            text?: string | null;
            url: string;
          }> | null;
          participant:
            | { __typename: "AssistantParticipant"; name: string }
            | {
                __typename: "UserParticipant";
                name: string;
                user: { __typename?: "User"; isMe: boolean };
              };
        }>;
      }>;
      currentVersion: {
        __typename?: "ChatVersion";
        id: string;
        updatedAt: any;
        messages: Array<{
          __typename?: "Message";
          id: string;
          updatedAt: any;
          text: string;
          chat: { __typename?: "Chat"; id: string };
          sources?: Array<{
            __typename?: "Source";
            type: MessageSourceType;
            citation?: {
              __typename?: "Citation";
              text?: string | null;
              url: string;
            } | null;
          }> | null;
          citations?: Array<{
            __typename?: "Citation";
            text?: string | null;
            url: string;
          }> | null;
          participant:
            | { __typename: "AssistantParticipant"; name: string }
            | {
                __typename: "UserParticipant";
                name: string;
                user: { __typename?: "User"; isMe: boolean };
              };
        }>;
        lastMessage?: {
          __typename?: "Message";
          id: string;
          text: string;
        } | null;
      };
    } | null;
  };
};

export type SendMessageMessageFragment = {
  __typename?: "Message";
  id: string;
  chat: { __typename?: "Chat"; id: string };
};

export type SendMessageChatVersionFragment = {
  __typename?: "ChatVersion";
  id: string;
  messages: Array<{
    __typename?: "Message";
    id: string;
    updatedAt: any;
    text: string;
    chat: { __typename?: "Chat"; id: string };
    sources?: Array<{
      __typename?: "Source";
      type: MessageSourceType;
      citation?: {
        __typename?: "Citation";
        text?: string | null;
        url: string;
      } | null;
    }> | null;
    citations?: Array<{
      __typename?: "Citation";
      text?: string | null;
      url: string;
    }> | null;
    participant:
      | { __typename: "AssistantParticipant"; name: string }
      | {
          __typename: "UserParticipant";
          name: string;
          user: { __typename?: "User"; isMe: boolean };
        };
  }>;
};

export type SendMessageChatFragment = {
  __typename?: "Chat";
  id: string;
  name: string;
  versions: Array<{
    __typename?: "ChatVersion";
    id: string;
    messages: Array<{
      __typename?: "Message";
      id: string;
      updatedAt: any;
      text: string;
      chat: { __typename?: "Chat"; id: string };
      sources?: Array<{
        __typename?: "Source";
        type: MessageSourceType;
        citation?: {
          __typename?: "Citation";
          text?: string | null;
          url: string;
        } | null;
      }> | null;
      citations?: Array<{
        __typename?: "Citation";
        text?: string | null;
        url: string;
      }> | null;
      participant:
        | { __typename: "AssistantParticipant"; name: string }
        | {
            __typename: "UserParticipant";
            name: string;
            user: { __typename?: "User"; isMe: boolean };
          };
    }>;
  }>;
  currentVersion: {
    __typename?: "ChatVersion";
    id: string;
    updatedAt: any;
    messages: Array<{
      __typename?: "Message";
      id: string;
      updatedAt: any;
      text: string;
      chat: { __typename?: "Chat"; id: string };
      sources?: Array<{
        __typename?: "Source";
        type: MessageSourceType;
        citation?: {
          __typename?: "Citation";
          text?: string | null;
          url: string;
        } | null;
      }> | null;
      citations?: Array<{
        __typename?: "Citation";
        text?: string | null;
        url: string;
      }> | null;
      participant:
        | { __typename: "AssistantParticipant"; name: string }
        | {
            __typename: "UserParticipant";
            name: string;
            user: { __typename?: "User"; isMe: boolean };
          };
    }>;
    lastMessage?: { __typename?: "Message"; id: string; text: string } | null;
  };
};

export type SendMessagesMutationVariables = Exact<{
  replyToMessageId: Scalars["ID"]["input"];
  message: MessageInput;
}>;

export type SendMessagesMutation = {
  __typename?: "Mutation";
  messageSend: {
    __typename?: "MessageSendPayloadV3";
    userErrors: Array<{
      __typename?: "UserError";
      message: string;
      field?: Array<string> | null;
    }>;
    chat?: {
      __typename?: "Chat";
      id: string;
      name: string;
      versions: Array<{
        __typename?: "ChatVersion";
        id: string;
        messages: Array<{
          __typename?: "Message";
          id: string;
          updatedAt: any;
          text: string;
          chat: { __typename?: "Chat"; id: string };
          sources?: Array<{
            __typename?: "Source";
            type: MessageSourceType;
            citation?: {
              __typename?: "Citation";
              text?: string | null;
              url: string;
            } | null;
          }> | null;
          citations?: Array<{
            __typename?: "Citation";
            text?: string | null;
            url: string;
          }> | null;
          participant:
            | { __typename: "AssistantParticipant"; name: string }
            | {
                __typename: "UserParticipant";
                name: string;
                user: { __typename?: "User"; isMe: boolean };
              };
        }>;
      }>;
      currentVersion: {
        __typename?: "ChatVersion";
        id: string;
        updatedAt: any;
        messages: Array<{
          __typename?: "Message";
          id: string;
          updatedAt: any;
          text: string;
          chat: { __typename?: "Chat"; id: string };
          sources?: Array<{
            __typename?: "Source";
            type: MessageSourceType;
            citation?: {
              __typename?: "Citation";
              text?: string | null;
              url: string;
            } | null;
          }> | null;
          citations?: Array<{
            __typename?: "Citation";
            text?: string | null;
            url: string;
          }> | null;
          participant:
            | { __typename: "AssistantParticipant"; name: string }
            | {
                __typename: "UserParticipant";
                name: string;
                user: { __typename?: "User"; isMe: boolean };
              };
        }>;
        lastMessage?: {
          __typename?: "Message";
          id: string;
          text: string;
        } | null;
      };
    } | null;
  };
};

export type MessageDayMessageFragment = {
  __typename?: "Message";
  text: string;
  updatedAt: any;
  sources?: Array<{
    __typename?: "Source";
    type: MessageSourceType;
    citation?: {
      __typename?: "Citation";
      text?: string | null;
      url: string;
    } | null;
  }> | null;
  citations?: Array<{
    __typename?: "Citation";
    text?: string | null;
    url: string;
  }> | null;
  participant:
    | { __typename: "AssistantParticipant"; name: string }
    | {
        __typename: "UserParticipant";
        name: string;
        user: { __typename?: "User"; isMe: boolean };
      };
};

export type MessageFormChatFragment = {
  __typename?: "Chat";
  id: string;
  name: string;
  versions: Array<{
    __typename?: "ChatVersion";
    id: string;
    messages: Array<{
      __typename?: "Message";
      id: string;
      updatedAt: any;
      text: string;
      chat: { __typename?: "Chat"; id: string };
      sources?: Array<{
        __typename?: "Source";
        type: MessageSourceType;
        citation?: {
          __typename?: "Citation";
          text?: string | null;
          url: string;
        } | null;
      }> | null;
      citations?: Array<{
        __typename?: "Citation";
        text?: string | null;
        url: string;
      }> | null;
      participant:
        | { __typename: "AssistantParticipant"; name: string }
        | {
            __typename: "UserParticipant";
            name: string;
            user: { __typename?: "User"; isMe: boolean };
          };
    }>;
  }>;
  currentVersion: {
    __typename?: "ChatVersion";
    id: string;
    updatedAt: any;
    messages: Array<{
      __typename?: "Message";
      id: string;
      updatedAt: any;
      text: string;
      chat: { __typename?: "Chat"; id: string };
      sources?: Array<{
        __typename?: "Source";
        type: MessageSourceType;
        citation?: {
          __typename?: "Citation";
          text?: string | null;
          url: string;
        } | null;
      }> | null;
      citations?: Array<{
        __typename?: "Citation";
        text?: string | null;
        url: string;
      }> | null;
      participant:
        | { __typename: "AssistantParticipant"; name: string }
        | {
            __typename: "UserParticipant";
            name: string;
            user: { __typename?: "User"; isMe: boolean };
          };
    }>;
    lastMessage?: { __typename?: "Message"; id: string; text: string } | null;
  };
};

export type MessageItemMessageFragment = {
  __typename?: "Message";
  text: string;
  updatedAt: any;
  citations?: Array<{
    __typename?: "Citation";
    text?: string | null;
    url: string;
  }> | null;
  participant:
    | { __typename: "AssistantParticipant"; name: string }
    | {
        __typename: "UserParticipant";
        name: string;
        user: { __typename?: "User"; isMe: boolean };
      };
};

export type MessageListChatVersionFragment = {
  __typename?: "ChatVersion";
  id: string;
  messages: Array<{
    __typename?: "Message";
    id: string;
    updatedAt: any;
    text: string;
    sources?: Array<{
      __typename?: "Source";
      type: MessageSourceType;
      citation?: {
        __typename?: "Citation";
        text?: string | null;
        url: string;
      } | null;
    }> | null;
    citations?: Array<{
      __typename?: "Citation";
      text?: string | null;
      url: string;
    }> | null;
    participant:
      | { __typename: "AssistantParticipant"; name: string }
      | {
          __typename: "UserParticipant";
          name: string;
          user: { __typename?: "User"; isMe: boolean };
        };
  }>;
};

export type ArticleMessageMessageFragment = {
  __typename?: "Message";
  text: string;
  sources?: Array<{
    __typename?: "Source";
    type: MessageSourceType;
    citation?: {
      __typename?: "Citation";
      text?: string | null;
      url: string;
    } | null;
  }> | null;
};

export type ProfileMenuQueryFragment = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    id: string;
    fullName: string;
    displayName?: string | null;
  } | null;
};

export type SidebarQueryFragment = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    id: string;
    fullName: string;
    displayName?: string | null;
  } | null;
};

export const AccountPageUserFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountPageUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "fullName" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "clinicName" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountPageUserFragment, unknown>;
export const ChatItemChatVersionFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastMessage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatItemChatVersionFragment, unknown>;
export const ChatItemChatFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ChatItemChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastMessage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatItemChatFragment, unknown>;
export const ChatListChatFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatListChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ChatItemChat" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastMessage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ChatItemChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatListChatFragment, unknown>;
export const MessageItemMessageFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageItemMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "citations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UserParticipant" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isMe" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageItemMessageFragment, unknown>;
export const AssistantGroupMessageFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AssistantGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sources" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "citation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageItemMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "citations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UserParticipant" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isMe" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssistantGroupMessageFragment, unknown>;
export const HumanGroupMessageFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageItemMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "citations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UserParticipant" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isMe" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HumanGroupMessageFragment, unknown>;
export const MessageDayMessageFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageDayMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AssistantGroupMessage" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanGroupMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageItemMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "citations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UserParticipant" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isMe" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AssistantGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sources" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "citation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageDayMessageFragment, unknown>;
export const MessageListChatVersionFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageListChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MessageDayMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageItemMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "citations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UserParticipant" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isMe" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AssistantGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sources" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "citation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageDayMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AssistantGroupMessage" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanGroupMessage" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageListChatVersionFragment, unknown>;
export const SendMessageMessageFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "chat" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendMessageMessageFragment, unknown>;
export const SendMessageChatVersionFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageListChatVersion" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageItemMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "citations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UserParticipant" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isMe" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AssistantGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sources" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "citation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageDayMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AssistantGroupMessage" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanGroupMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageListChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MessageDayMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "chat" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendMessageChatVersionFragment, unknown>;
export const SendMessageChatFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ChatItemChat" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "versions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageChatVersion" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastMessage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageItemMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "citations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UserParticipant" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isMe" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AssistantGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sources" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "citation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageDayMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AssistantGroupMessage" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanGroupMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageListChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MessageDayMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "chat" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ChatItemChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageListChatVersion" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageMessage" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendMessageChatFragment, unknown>;
export const CreateChatChatFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreateChatChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SendMessageChat" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastMessage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ChatItemChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageItemMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "citations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UserParticipant" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isMe" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AssistantGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sources" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "citation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageDayMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AssistantGroupMessage" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanGroupMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageListChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MessageDayMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "chat" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageListChatVersion" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ChatItemChat" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "versions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageChatVersion" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateChatChatFragment, unknown>;
export const MessageFormChatFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageFormChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SendMessageChat" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastMessage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ChatItemChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageItemMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "citations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UserParticipant" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isMe" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AssistantGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sources" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "citation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageDayMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AssistantGroupMessage" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanGroupMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageListChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MessageDayMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "chat" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageListChatVersion" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ChatItemChat" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "versions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageChatVersion" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageFormChatFragment, unknown>;
export const ArticleMessageMessageFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ArticleMessageMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sources" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "citation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArticleMessageMessageFragment, unknown>;
export const ProfileMenuQueryFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfileMenuQuery" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Query" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "fullName" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileMenuQueryFragment, unknown>;
export const SidebarQueryFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SidebarQuery" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Query" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfileMenuQuery" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfileMenuQuery" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Query" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "fullName" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SidebarQueryFragment, unknown>;
export const SignupPageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SignupPage" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignupPageQuery, SignupPageQueryVariables>;
export const InternalLayoutQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "InternalLayoutQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SidebarQuery" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfileMenuQuery" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Query" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "fullName" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SidebarQuery" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Query" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfileMenuQuery" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InternalLayoutQueryQuery,
  InternalLayoutQueryQueryVariables
>;
export const ChatPageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ChatPage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "chat" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MessageFormChat" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentVersion" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "MessageListChatVersion" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastMessage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ChatItemChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageItemMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "citations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UserParticipant" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isMe" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AssistantGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sources" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "citation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageDayMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AssistantGroupMessage" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanGroupMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageListChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MessageDayMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "chat" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageListChatVersion" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ChatItemChat" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "versions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageChatVersion" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageFormChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SendMessageChat" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatPageQuery, ChatPageQueryVariables>;
export const LogoutPageQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LogoutPageQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LogoutPageQueryQuery,
  LogoutPageQueryQueryVariables
>;
export const AccountQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AccountQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AccountPageUser" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountPageUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "fullName" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "clinicName" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountQueryQuery, AccountQueryQueryVariables>;
export const UpdateAccountMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateAccountMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "profile" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ProfileInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "profileUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "profile" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "profile" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "field" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "AccountPageUser" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountPageUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "fullName" } },
          { kind: "Field", name: { kind: "Name", value: "displayName" } },
          { kind: "Field", name: { kind: "Name", value: "clinicName" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccountMutationMutation,
  UpdateAccountMutationMutationVariables
>;
export const AuthTrackerUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthTrackerUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "me" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "fullName" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "clinicName" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthTrackerUserQuery,
  AuthTrackerUserQueryVariables
>;
export const RegisterDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Register" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fullName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "recaptchaToken" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "displayName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clinicName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "register" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "profile" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "credentials" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "email" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "email" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "password" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "password" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "profile" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "fullName" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "fullName" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "displayName" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "displayName" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "clinicName" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "clinicName" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "challenge" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "recaptcha_token" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "recaptchaToken" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "field" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegisterMutation, RegisterMutationVariables>;
export const ChatListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ChatList" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "chats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ChatListChat" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastMessage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ChatItemChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatListChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ChatItemChat" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChatListQuery, ChatListQueryVariables>;
export const DeleteChatDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeleteChat" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "chatId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "chatDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "chatId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "chatId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "field" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteChatMutation, DeleteChatMutationVariables>;
export const ChatNameFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ChatNameForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "chatId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "chat" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ChatInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "chatUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "chatId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "chatId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "chat" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "chat" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "field" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "chat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChatNameFormMutation,
  ChatNameFormMutationVariables
>;
export const CreateChatDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateChat" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "chat" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ChatInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "message" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "MessageInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "chatCreate" },
            name: { kind: "Name", value: "chatCreateV3" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "chat" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "chat" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "message" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "message" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userErrors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "field" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "chat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CreateChatChat" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastMessage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ChatItemChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageItemMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "citations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UserParticipant" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isMe" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AssistantGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sources" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "citation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageDayMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AssistantGroupMessage" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanGroupMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageListChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MessageDayMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "chat" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageListChatVersion" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ChatItemChat" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "versions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageChatVersion" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreateChatChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "SendMessageChat" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateChatMutation, CreateChatMutationVariables>;
export const SendMessagesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SendMessages" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "replyToMessageId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "message" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "MessageInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "messageSend" },
            name: { kind: "Name", value: "messageSendV3" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "replyToMessageId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "replyToMessageId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "message" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "message" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userErrors" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "field" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "chat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "SendMessageChat" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastMessage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ChatItemChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ChatItemChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageItemMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "text" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "citations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "participant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "UserParticipant" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isMe" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AssistantGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sources" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "citation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanGroupMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageItemMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageDayMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "AssistantGroupMessage" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanGroupMessage" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MessageListChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MessageDayMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageMessage" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "chat" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChatVersion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ChatVersion" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "MessageListChatVersion" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "messages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageMessage" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SendMessageChat" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Chat" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ChatItemChat" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "versions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageChatVersion" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentVersion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SendMessageChatVersion" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendMessagesMutation,
  SendMessagesMutationVariables
>;
